import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import ReactMarkdown from "react-markdown";

import { renderMarkdownLink } from "./common/helper";
import Title from "./title";

const About = ({ abouts, showLink }) => {
  return (
    <section className="page about-page" style={{ background: showLink ? "var(--clr-grey-9)" : "" }}>
      {abouts.map((item, i) => {
        const image = getImage(item.image);
        return (
          <div
            className="section-center about-center project"
            style={{
              marginBottom: "4rem",
              direction: i % 2 !== 0 ? "rtl" : "none"
            }}
            key={i}
          >
            <GatsbyImage image={image} alt={item.title} className="about-img" />
            <article
              className="about-text"
              style={{
                direction: i % 2 !== 0 ? "initial" : "none"
              }}
            >
              <Title title={item.title} />
              <ReactMarkdown
                children={item.content.content}
                linkTarget="_blank"
                components={{
                  a: renderMarkdownLink
                }}
              />
              <div className="about-stack">
                {item.tags &&
                  item.tags.map((tag) => {
                    return <span key={tag}>{tag}</span>;
                  })}
              </div>
            </article>
          </div>
        );
      })}
      {showLink && (
        <Link to="/qui-sommes-nous/" className="btn center-btn">
          En savoir plus
        </Link>
      )}
    </section>
  );
};

export default About;
