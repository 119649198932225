import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import { renderMarkdownLink } from "./common/helper";
import { Container, Section } from "./common/layout/containers";
import Title from "./title";
import GTAButtonLink from "./ui/gta-button-link";
import Message from "./ui/message";

const Header = ({ data }) => {
  return (
    <div style={{ paddingTop: "5rem" }}>
      <Title title="Nos programmes pour t'accompagner" />
      <section className="blog-template" style={{ paddingBottom: "0" }}>
        <div className="section-center">
          <Container>
            <Message
              title="Voulez-vous en savoir plus sur nos programmes d'accompagnement ?"
              style={{ background: "var(--clr-grey-9)" }}
            >
              <GTAButtonLink url={"/contact"}>Contactez-nous</GTAButtonLink>
            </Message>
          </Container>
        </div>
      </section>
      {data.map((item, i) => {
        const image = getImage(item.image);
        return (
          <StyledSection id="auteure" anchor author style={{ paddingBottom: "100px" }} key={i} bg={i % 2}>
            <Container>
              <Flex>
                <HeaderTextGroup>
                  <h2>{item.title}</h2>
                  <ReactMarkdown
                    children={item.content.content}
                    linkTarget="_blank"
                    components={{
                      a: renderMarkdownLink
                    }}
                  />
                </HeaderTextGroup>
                <ImageWrapper>
                  <StyledImage>
                    <GatsbyImage image={image} alt={item.title} className="about-img" placeholder="tracedSVG" />
                  </StyledImage>
                  <br />
                </ImageWrapper>
              </Flex>
            </Container>
          </StyledSection>
        );
      })}
      <section className="blog-template" style={{ paddingBottom: "0" }}>
        <div className="section-center">
          <Container>
            <Message
              title="Voulez-vous en savoir plus sur nos programmes d'accompagnement ?"
              style={{ background: "var(--clr-grey-9)" }}
            >
              <GTAButtonLink url={"/contact"}>Contactez-nous</GTAButtonLink>
            </Message>
          </Container>
        </div>
      </section>
    </div>
  );
};

export default Header;

const StyledSection = styled(Section)`
  background-color: ${(props) => (props.bg === 1 ? props.theme.color.background.light : "none")};
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    clip-path: polygon(0 0, 100% 3%, 100% 100%, 0% 100%);
  }
`;
const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    // margin-bottom: 48px;
  }
`;

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1.7fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`;

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`;

const StyledImage = styled.div`
  width: 300px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 300px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
  }
`;
