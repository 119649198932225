import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import { FaCalendarAlt, FaClock, FaUserEdit } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

import commentBox from "commentbox.io";

import BlogCategories from "../blog-categories";
import Blogs from "../blogs";
import { renderMarkdownLink } from "../common/helper";
import Container from "../container";
import Feadback from "../forms/feedback";
import Share from "../forms/share";
import Subscribe from "../forms/subscribe";
import Layout from "../layout";
import Seo from "../seo";
import GTAButtonLink from "../ui/gta-button-link";
import Message from "../ui/message";

const PostTemplate = ({ data, rootPath, locationPathname, categoriesSummary }) => {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    commentBox(process.env.GATSBY_COMMENTS_BOX_ID, {
      backgroundColor: null, // default transparent
      textColor: null, // default black
      subtextColor: null // default grey
    });
  });

  const {
    post: {
      body: { body: content },
      title,
      description: { description },
      heroImage,
      contributors,
      publishDate: date,
      categories,
      readTime
    },
    relatedPosts: { nodes: relatedPosts }
  } = data;

  const image = getImage(heroImage);

  const seoImage = image
    ? {
        width: image.width,
        height: image.height,
        src: image?.images?.fallback?.src
      }
    : null;

  const authors = contributors?.map((c) => c.name);
  const author = `${authors?.slice(0, -1).join(", ")}  ${authors?.length > 1 ? "et " : ""}${authors?.slice(-1)}`;

  return (
    <Layout>
      <Seo title={title} description={description} image={seoImage} pathname={locationPathname} />
      <section className="page blog-template" style={{ paddingBottom: "0" }}>
        <div className="section-center">
          <Container>
            <BlogHeader>
              <h1>{title}</h1>
              <span>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <FaUserEdit style={{ marginRight: "0.2rem" }} /> {author}
                </span>
                <span style={{ display: "flex", alignItems: "center" }}>
                  <FaCalendarAlt style={{ marginRight: "0.2rem" }} /> {date} -{" "}
                  <FaClock style={{ margin: "0 0.2rem" }} /> {readTime} min
                </span>
              </span>
              <Share url={locationPathname} />
              <BlogCategories
                categories={categories}
                style={{ margin: "0" }}
                rootPath={rootPath}
                categoriesSummary={categoriesSummary}
              />
            </BlogHeader>
            <GatsbyImage image={image} alt={title} style={{ overflow: "initial" }} />
            <article className="blog-content" /*style={{ marginTop: "6rem" }}*/>
              <Markdown
                children={content}
                linkTarget="_blank"
                components={{
                  a: renderMarkdownLink
                }}
              />
              <BlogHeader style={{ marginTop: "4rem" }}>
                <span
                  style={{
                    borderTop: "2px solid var(--clr-primary-5)",
                    padding: "10px"
                  }}
                >
                  {author}
                </span>
              </BlogHeader>
            </article>
            <Comments className="commentbox" />
          </Container>
        </div>
      </section>
      <section className="blog-template" style={{ paddingBottom: "0" }}>
        <div className="section-center">
          <Container>
            {categories?.some((item) => item.toLowerCase() === "témoignage") ? (
              <Message title="Voulez-vous partager un témoignage ?" style={{ background: "var(--clr-grey-9)" }}>
                <GTAButtonLink url={"/contact"}>Contactez-nous</GTAButtonLink>
              </Message>
            ) : null}
            <Feadback article={title} author={author} pathname={locationPathname} />
          </Container>
        </div>
      </section>
      <Blogs blogPosts={relatedPosts} title={"À lire aussi"} showLink link="/" noDescription fit rootPath={rootPath} />
      <Subscribe />
    </Layout>
  );
};

export default PostTemplate;

const BlogHeader = styled.div`
  margin: 2rem 0;
  h1 {
    font-size: 2rem;
  }
  h3 {
    text-transform: none;
  }
  span {
    color: var(--clr-grey-5);
  }

  @media screen and (max-width: 800px) {
    h1 {
      font-size: 1.75rem;
    }
  }
`;

const Markdown = styled(ReactMarkdown)`
  list-style: unset;
  font-size: 1.1rem;
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 1rem;
  }
  h5,
  h6 {
    font-size: 0.87rem;
  }
  a {
    color: var(--clr-primary-8);
    text-decoration: underline;

    :hover {
      color: var(--clr-primary-5);
    }
  }
`;

const Comments = styled.div``;
